import React, { useRef } from "react";
import SectionHeader from "../components/sectionHeader";
import Layout from "../components/layout";
import downArrow from "../assets/icons/icon-down-arrow.png";
import enrolMaintain from "../assets/icons/icon-maintain.png";
import online from "../assets/online.png";
import "../components/enrol/enrol.scss";
import { Fade } from "react-reveal";
import { useStaticQuery, graphql, navigate } from "gatsby";
import { Helmet } from "react-helmet";
import ReactMarkdown from "react-markdown";
import { Container, Row, Col } from "react-bootstrap";
import BillingFeatures from "../components/features/EngageFeatures";

import FeaturesBackgroundBanner from "../components/features/FeaturesBackgroundBanner";

import "../components/features/moreFeaturesLinksfeodaflow.scss";
import { Nav } from "react-bootstrap";

import ProcureFeatures from "../components/features/EngageFeatures";
import AssetsFeatures from "../components/enrol/KeyFeatures";

function Billing() {
  const BillingPageData = useStaticQuery(graphql`
    query {
      allStrapiBillingPage {
        nodes {
          KeyFeatures {
            Icon {
              publicURL
            }
            Title
            id
            Tab {
              Content
              EventKey
              Header2
              Header3
              Title
              id
            }
          }

          PageHeader {
            Content
            Image {
              publicURL
            }
            Text
            Title
            TitleHighlight
            id
          }
          PageTitle
        }
      }
      allStrapiFeodafinancePage {
        nodes {
          PageHeader {
            Image {
              publicURL
            }
            Title
            TitleHighlight
            Content
            id
          }
        }
      }
      allStrapiProcurePage {
        nodes {
          KeyFeatures {
            Icon {
              publicURL
            }
            Title
            id
            Tab {
              Content
              EventKey
              Header2
              Header3
              Title
              id
            }
          }

          PageHeader {
            Content
            Image {
              publicURL
            }
            Text
            Title
            TitleHighlight
            id
          }
          PageTitle
        }
      }
      allStrapiAssetsPage {
        nodes {
          KeyFeatures {
            Icon {
              publicURL
            }
            Title
            id
            Tab {
              Content
              EventKey
              Header2
              Header3
              Title
              id
            }
          }

          PageHeader {
            Content
            Image {
              publicURL
            }
            Text
            Title
            TitleHighlight
            id
          }
          PageTitle
        }
      }
    }
  `);
  const listInnerRef = useRef();
  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        // TO SOMETHING HERE
        console.log("Reached bottom");
        // navigate('/');
      }
    }
  };
  const pageData = BillingPageData.allStrapiBillingPage.nodes[0];
  const feodapageData = BillingPageData.allStrapiFeodafinancePage.nodes[0];
  const procurepageData = BillingPageData.allStrapiProcurePage.nodes[0];
  const assetspageData = BillingPageData.allStrapiAssetsPage.nodes[0];

  return (
    <Layout>
      <Helmet defer={false}>
        <title>{pageData.PageTitle}</title>
      </Helmet>
      {/* Newly added */}
      <Container>
        <Row className="enrol-hero">
          <Col md>
            <Fade left>
              <div>
                <SectionHeader
                  icon={enrolMaintain}
                  title={""}
                  highlight={""}
                  //text={feodapageData.PageHeader[0].Text}
                />
                <h1>
                  {feodapageData.PageHeader[0].Title}
                  <strong>{feodapageData.PageHeader[0].TitleHighlight}</strong>
                </h1>
                <ReactMarkdown source={feodapageData.PageHeader[0].Content} />
                <img src={downArrow} width="60" alt="icon"></img>
              </div>
            </Fade>
          </Col>

          <Col md className="hero-image">
            <img
              src={feodapageData.PageHeader[0].Image.publicURL}
              className="drop-shadow xyz-in"
              alt="process"
              xyz="fade right stagger delay-4 duration-10"
              width="600"
            ></img>
          </Col>
        </Row>
      </Container>
      {/* Newly added */}

      <section id="billingsection" STYLE={"display:none"}>
        <Container
          className="enrol"
          onScroll={() => onScroll()}
          ref={listInnerRef}
        >
          <Row className="enrol-hero">
            <Col md>
              <Fade left>
                <div>
                  <SectionHeader
                    //icon={enrolMaintain}
                    title={pageData.PageHeader[0].Title}
                    highlight={pageData.PageHeader[0].TitleHighlight}
                    text={pageData.PageHeader[0].Text}
                  />

                  <ReactMarkdown source={pageData.PageHeader[0].Content} />
                </div>
              </Fade>
            </Col>
            <Col md className="hero-image">
              <img
                src={pageData.PageHeader[0].Image.publicURL}
                className="drop-shadow xyz-in"
                alt="process"
                xyz="fade right stagger delay-4 duration-10"
                width="600"
              ></img>
            </Col>
          </Row>
          <BillingFeatures data={pageData.KeyFeatures[0]} />
        </Container>
      </section>

      <section id="procuresection" STYLE={"display:block"}>
        <Container
          className="enrol"
          onScroll={() => onScroll()}
          ref={listInnerRef}
        >
          <Row className="enrol-hero">
            <Col md>
              <Fade left>
                <div>
                  <SectionHeader
                    //icon={enrolMaintain}
                    title={procurepageData.PageHeader[0].Title}
                    highlight={procurepageData.PageHeader[0].TitleHighlight}
                    text={procurepageData.PageHeader[0].Text}
                  />

                  <ReactMarkdown
                    source={procurepageData.PageHeader[0].Content}
                  />
                </div>
              </Fade>
            </Col>
            <Col md className="hero-image">
              <img
                src={procurepageData.PageHeader[0].Image.publicURL}
                className="drop-shadow xyz-in"
                alt="process"
                xyz="fade right stagger delay-4 duration-10"
                width="600"
              ></img>
            </Col>
          </Row>
          <ProcureFeatures data={procurepageData.KeyFeatures[0]} />
        </Container>
      </section>

      <section id="assetssection" STYLE={"display:none"}>
        <Container
          className="enrol"
          onScroll={() => onScroll()}
          ref={listInnerRef}
        >
          <Row className="enrol-hero">
            <Col md>
              <Fade left>
                <div>
                  <SectionHeader
                    //icon={enrolMaintain}
                    title={assetspageData.PageHeader[0].Title}
                    highlight={assetspageData.PageHeader[0].TitleHighlight}
                    text={assetspageData.PageHeader[0].Text}
                  />

                  <ReactMarkdown
                    source={assetspageData.PageHeader[0].Content}
                  />
                </div>
              </Fade>
            </Col>
            <Col md className="hero-image">
              <img
                src={assetspageData.PageHeader[0].Image.publicURL}
                className="drop-shadow xyz-in"
                alt="process"
                xyz="fade right stagger delay-4 duration-10"
                width="600"
              ></img>
            </Col>
          </Row>
          <AssetsFeatures data={assetspageData.KeyFeatures[0]} />
        </Container>
      </section>
      <section className="feature-links">
        <Nav>
          <button
            id="billingbtn"
            onClick={Togglefuncbilling}
            className="button"
          >
            Billing
          </button>
          <button
            id="procurebtn"
            onClick={Togglefuncprocure}
            className="button active1"
          >
            Procure to Pay
          </button>
          <button id="assetsbtn" onClick={Togglefuncassets} className="button">
            Assets
          </button>
        </Nav>
      </section>

      <FeaturesBackgroundBanner />
    </Layout>
  );
}
function Togglefuncbilling() {
  if (
    document.getElementById("billingsection") &&
    document.getElementById("procuresection") &&
    document.getElementById("assetssection")
  ) {
    const targetDiv1 = document.getElementById("billingsection");
    const targetDiv2 = document.getElementById("procuresection");
    const targetDiv3 = document.getElementById("assetssection");
    //const btn = document.getElementById("toggle");

    targetDiv1.style.display = "block";
    targetDiv2.style.display = "none";
    targetDiv3.style.display = "none";
    var currentbtn;
    currentbtn = document.getElementsByClassName("button active1");
    currentbtn[0].classList.remove("active1");
    document.getElementById("billingbtn").classList.add("active1");
  }
}
function Togglefuncprocure() {
  if (
    document.getElementById("billingsection") &&
    document.getElementById("procuresection") &&
    document.getElementById("assetssection")
  ) {
    const targetDiv1 = document.getElementById("billingsection");
    const targetDiv2 = document.getElementById("procuresection");
    const targetDiv3 = document.getElementById("assetssection");
    //const btn = document.getElementById("toggle");

    targetDiv1.style.display = "none";
    targetDiv2.style.display = "block";
    targetDiv3.style.display = "none";
    var currentbtn;
    currentbtn = document.getElementsByClassName("button active1");
    currentbtn[0].classList.remove("active1");
    document.getElementById("procurebtn").classList.add("active1");
  }
}

function Togglefuncassets() {
  if (
    document.getElementById("billingsection") &&
    document.getElementById("procuresection") &&
    document.getElementById("assetssection")
  ) {
    const targetDiv1 = document.getElementById("billingsection");
    const targetDiv2 = document.getElementById("procuresection");
    const targetDiv3 = document.getElementById("assetssection");
    //const btn = document.getElementById("toggle");

    targetDiv1.style.display = "none";
    targetDiv2.style.display = "none";
    targetDiv3.style.display = "block";
    var currentbtn;
    currentbtn = document.getElementsByClassName("button active1");
    currentbtn[0].classList.remove("active1");
    document.getElementById("assetsbtn").classList.add("active1");
  }
}
export default Billing;
